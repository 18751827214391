import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Seo from "../components/Seo";
import PriceBlock from "../components/PriceBlock";

const PakkettenPage = ({data}) => {
    const {wpPage: page} = data;

    return (
        <Layout>
            <Seo seo={page.seo} />
            {/*<h1 className="page-heading">{page.title}</h1>*/}
            <div className="container mx-auto lg:w-2/3 w-2/3 py-10">
                <div className="my-5" dangerouslySetInnerHTML={{__html: page.content}} />
            </div>
            <PriceBlock/>

        </Layout>
    );
}

export default PakkettenPage;

export const PakkettenPagePageQuery = graphql`
  query PakkettenById($id: String!) {
  allWp {
        nodes {
          options {
            acf_prices {
             acfPriceTitle
             acfPriceDesc
             acfPriceBelow
                 prijzen {
                  priceTitle
                  pricePrijs
                  priceFrequency
                 
                  priceUsps {
                     usp
               }
             }
            }
          }
        }
      }
  wpPage(id: { eq: $id }) {
      seo {  
          canonical
          title
          metaDesc
          focuskw
                
      }
      uri
      title
      content
    }
}`;
